<template>
  <div class="content">
    <div class="print-content">
      <div class="first">
        <p>①下载控件并安装，<span style="color:#f00">安装完成后请重启浏览器!</span></p>
        <a class="download-btn" style="text-decoration: none;" href="https://do-dora-formal.oss-cn-chengdu.aliyuncs.com/software/CLodop_Setup_for_Win32NT.exe">
          <img src="@/assets/imgs/downLoad.png" />
          <div>下载</div>
        </a>
      </div>
      <el-image :src="require('@/assets/imgs/one.png')" :preview-src-list="srcList" @click="bigImg(require('@/assets/imgs/one.png'))" width="296px" height="198px" style="margin-left:30px"></el-image>
    <div class="two">
      <p>②请检查您的电脑是否成功连接打印机</p>
    </div>
    <div class="two">
      <p>③打开控制面板-软件和声音-查看设备和打印机</p>
      <el-image  :src="require('@/assets/imgs/two.png')" :preview-src-list="srcList" @click="bigImg(require('@/assets/imgs/two.png'))" width="406px" height="341px" style="margin-top:30px"></el-image>
    </div>
    <div>
      <p>④选择自己的小票打印机-右键设置为默认打印机</p>
      <el-image :src="require('@/assets/imgs/three.png')" :preview-src-list="srcList" @click="bigImg(require('@/assets/imgs/three.png'))" width="406px" height="341px" style="margin-top:30px"></el-image>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      srcList:[]
    }
  },
  methods:{
    bigImg(imgUrl){
        this.srcList[0]=imgUrl
        console.log(imgUrl)
    }
  }
};
</script>

<style lang='less'>
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
  .print-content{
      margin: 0 auto;
      width: 450px;
  }
  .download-btn {
    width: 80px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    background: #cd0550;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-left: 20px;
    img {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }
  }
  .first {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .two{
      margin: 30px 0;
  }
}
</style>